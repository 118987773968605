@font-face {
  src: local('Raleway'),
    url('./assets/fonts/Raleway-Regular.ttf') format('truetype');
  font-family: 'Raleway';
  font-style: normal;
  font-display: block;
}

:root {
  --clr-dark-1: #242936;
  --clr-dark-2: #21262d;
  --clr-dark-3: #161b22;
  --clr-dark-4: #0d1117;
  --clr-dark-5: rgb(0, 0, 0);

  --clr-gold-1: rgb(41, 41, 41);

  --clr-grey-1: #c6cdd5;
  --clr-grey-2: #89929b;
  --clr-grey-3: #506477;
  --clr-grey-4: rgb(34, 34, 34);
  --clr-grey-5: #3a5e60;

  --clr-blue-1: #add7ff;
  --clr-blue-2: #91b4d5;
  --clr-blue-3: #77bdfb;

  --clr-green-1: #27e8a7;
  --clr-green-2: #32ae85;
  --clr-green-3: #42675a;

  --clr-red-1: #cf6679;
  --clr-red-2: #df4576;

  --clr-orange-1: #faa356;
  --clr-white: #fff;
}

html {
  background-color: var(--clr-dark-1);
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Raleway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-dark-1);
}

#root {
  background-color: var(--clr-dark-1);
}
.App {
  background-color: var(--clr-dark-1);
}

.bg-dark-1 {
  background-color: var(--clr-dark-1) !important;
}
.bg-dark-2 {
  background-color: var(--clr-dark-2) !important;
}
.bg-dark-3 {
  background-color: var(--clr-dark-3) !important;
}
.bg-dark-4 {
  background-color: var(--clr-dark-4) !important;
}

.bg-grey-1 {
  background-color: var(--clr-grey-1) !important;
}
.bg-grey-2 {
  background-color: var(--clr-grey-2) !important;
}
.bg-grey-3 {
  background-color: var(--clr-grey-3) !important;
}
.bg-grey-4 {
  background-color: var(--clr-grey-4) !important;
}
.bg-grey-5 {
  background-color: var(--clr-grey-5) !important;
}

.bg-blue-1 {
  background-color: var(--clr-blue-1) !important;
}
.bg-blue-2 {
  background-color: var(--clr-blue-2) !important;
}
.bg-blue-3 {
  background-color: var(--clr-blue-3) !important;
}

.bg-green-1 {
  background-color: var(--clr-green-1) !important;
}
.bg-green-2 {
  background-color: var(--clr-green-2) !important;
}
.bg-green-3 {
  background-color: var(--clr-green-3) !important;
}

.bg-red-1 {
  background-color: var(--clr-red-1) !important;
}
.bg-red-2 {
  background-color: var(--clr-red-2) !important;
}

.bg-orange-1 {
  background-color: var(--clr-orange-1) !important;
}

.bg-gold-1 {
  background-color: var(--clr-gold-1) !important;
}

.text-dark-1 {
  color: var(--clr-dark-1);
}
.text-dark-2 {
  color: var(--clr-dark-2);
}
.text-dark-3 {
  color: var(--clr-dark-3);
}
.text-dark-4 {
  color: var(--clr-dark-4);
}

.text-grey-1 {
  color: var(--clr-grey-1);
}
.text-grey-2 {
  color: var(--clr-grey-2);
}
.text-grey-3 {
  color: var(--clr-grey-3);
}

.text-blue-1 {
  color: var(--clr-blue-1);
}
.text-blue-2 {
  color: var(--clr-blue-2);
}
.text-blue-3 {
  color: var(--clr-blue-3);
}

.text-green-1 {
  color: var(--clr-green-1);
}
.text-green-2 {
  color: var(--clr-green-2);
}
.text-green-3 {
  color: var(--clr-green-3);
}

.text-red-1 {
  color: var(--clr-red-1);
}
.text-red-2 {
  color: var(--clr-red-2);
}

.text-orange-1 {
  color: var(--clr-orange-1);
}

.text-orange {
  color: var(--clr-white);
}

.btn-outline-green-1 {
  background-color: transparent !important;
  color: var(--clr-green-1) !important;
  border-color: var(--clr-green-1) !important;
  margin: 3px;
}
.btn-active-green {
  background-color: var(--clr-green-1) !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}
.btn-active-red {
  background-color: var(--clr-red-1) !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}
.btn-active-blue {
  background-color: var(--clr-blue-1) !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}
.btn-active-orange {
  background-color: var(--clr-orange-1) !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}

.btn-active-red {
  background-color: var(--clr-red-1) !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}

.btn-outline-dark-1 {
  background-color: transparent !important;
  color: var(--clr-dark-1) !important;
  border-color: var(--clr-dark-1) !important;
  margin: 3px;
}

.btn-outline-blue-1 {
  background-color: transparent !important;
  color: var(--clr-blue-1) !important;
  border-color: var(--clr-blue-1) !important;
  margin: 3px;
}
.btn-outline-red-1 {
  background-color: transparent !important;
  color: var(--clr-red-1) !important;
  border-color: var(--clr-red-1) !important;
  margin: 3px;
}

.btn-outline-orange-1 {
  background-color: transparent !important;
  color: var(--clr-orange-1) !important;
  border-color: var(--clr-orange-1) !important;
  margin: 3px;
}
.btn-outline-green-1 {
  background-color: transparent !important;
  color: var(--clr-green-1) !important;
  border-color: var(--clr-green-1) !important;
  margin: 3px;
}
.btn-outline-white-1 {
  background-color: transparent !important;
  color: white !important;
  border-color: white !important;
  margin: 3px;
}
.bg-outline-green-1 {
  background-color: transparent !important;
  color: var(--clr-green-1) !important;
  border-color: var(--clr-green-1) !important;
  margin: 3px;
}

.btn-rounded {
  border-radius: 5px;
}

.search-input {
  background-color: transparent !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--clr-green-1);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-input::placeholder {
  color: var(--clr-white) !important;
  opacity: 1; /* Firefox */
}

.card-header-logo {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid;
}
.spinner-large {
  width: 200px !important;
  height: 200px !important;
}

.text-right {
  text-align: right !important;
}

.result {
  padding: 1rem 2rem;
  border-radius: 10px;
  border-color: var(--clr-orange-1);
  border-width: 2px;
  border-style: solid;
  border-color: var(--clr-orange-1);
}

pre {
  background-color: var(--clr-orange-1) !important;
  color: var(--clr-dark-1);
  padding: 1rem 2rem;
  border-radius: 10px;
}

.margin-right {
  margin-right: 10px !important;
}

.markdown-input {
  background-color: transparent !important;
  color: var(--clr-blue-3) !important;
}
.bold {
  font-weight: bold;
}

.markdown-input::placeholder {
  min-height: 100%;
  color: #ced4da !important;
  opacity: 1; /* Firefox */
}

.modal-content {
  background-color: var(--clr-green-3) !important;
  border-radius: 10px;
  border-color: var(--clr-dark-3);
  border-width: 2px;
  border-style: solid;
  border-color: var(--clr-green-3);
}

.dropdown-menu {
  background-color: var(--clr-dark-3) !important;
  color: var(--clr-green-1) !important;
  border-radius: 10px;
  border-color: var(--clr-green-1);
  border-width: 2px;
  border-style: solid;
  border-color: var(--clr-green-1);
}

.remove-link-decoration {
  text-decoration: none;
}

.remove-link-decoration:hover {
  color: var(--clr-orange-1);
}

.img-profile {
  margin-right: 5px;
  width: 50px;
  height: 50px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid;
}

.result img {
  width: 100% !important;
}

th {
  color: var(--clr-dark-1);
  text-align: center;
  background-color: var(--clr-green-2) !important;
}
td {
  color: var(--clr-green-1);
}

.trans-90 {
  opacity: 90%;
}

.img-container {
  border: var(--clr-green-1) 1px solid;
  padding: 5px;
}

.section {
  background-color: transparent !important;
  border: var(--clr-grey-5) solid 1px;
}

.btn-rounded {
  border-radius: 50%;
}

.card-red {
  color: white;
  border: transparent;
  background: var(--clr-red-1);
  background: linear-gradient(
    to right bottom,
    rgba(207, 102, 121, 0.07),
    rgba(207, 102, 121, 0.03)
  );
}

.card-blue {
  color: white;
  border: transparent;
  background: var(--clr-blue-1);
  background: linear-gradient(
    to right bottom,
    rgba(173, 215, 255, 0.07),
    rgba(173, 215, 255, 0.03)
  );
}

.card-orange {
  color: white;
  border: transparent;
  background: var(--clr-orange-1);
  background: linear-gradient(
    to right bottom,
    rgba(250, 163, 86, 0.07),
    rgba(250, 163, 86, 0.03)
  );
}

.card-grey {
  color: white;
  border: transparent;
  background: var(--clr-orange-1);
  background: linear-gradient(
    to right bottom,
    rgba(137, 146, 155, 0.07),
    rgba(137, 146, 155, 0.03)
  );
}

.card-green {
  color: white;
  border: transparent;
  background: var(--clr-green-1);
  background: linear-gradient(
    to right bottom,
    rgba(39, 232, 167, 0.07),
    rgba(39, 232, 167, 0.03)
  );
}

.login {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url('./assets//img/hacker.jpg');
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  filter: blur(4px);
}

.bg-image {
  background-image: url('./assets//img/hacker.jpg');
  filter: blur(8px);
  -webkit-filter: blur(8px);

  height: 100vh;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  height: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
  padding: 20px;
  z-index: 2;
}

.center-v {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100vh;
}

.minw-400 {
  min-width: 400px;
}
